<template>
    <v-app>
        <v-dialog
                v-model="dialog" max-width="900">
            <v-card>
                <v-card-title>
                    {{`${edit?'Update':'Add'}`}} Assignment Questions
                </v-card-title>
                <v-card-text>
                    <div class="row">
                        <div class="col-12">
                            <v-text-field outlined dense v-model="assignment_question.title"
                                          placeholder=" Title"></v-text-field>
                            <span class="text-danger"
                                  v-if="$v.assignment_question.title.$error"> Title is required</span>
                        </div>
                        <div class="col-12">
                            <ckeditor v-model="assignment_question.description"></ckeditor>
                            <span class="text-danger"
                                  v-if="$v.assignment_question.description.$error">Description is required</span>
                        </div>
                        <div class="col-4">
                            <v-select
                                    :items="answer_acceptances"
                                    v-model="assignment_question.answer_acceptance"
                                    outlined
                                    dense
                                    label="Answer Acceptance"
                                    item-text="title"
                                    item-value="value"
                            >
                            </v-select>
                        </div>
                        <div class="col-4">
                            <v-text-field outlined dense v-model="assignment_question.max_ans_to_select"
                                          placeholder=" Max Answer Select"></v-text-field>
                        </div>
                        <div class="col-4">
                            <v-text-field outlined dense v-model="assignment_question.correct_ans"
                                          placeholder=" Correct  Answer"></v-text-field>
                        </div>
                        <div class="col-6">
                            <v-select
                                    :items="marking_types"
                                    v-model="assignment_question.marking_type"
                                    outlined
                                    dense
                                    label="Marking Type "
                                    item-text="title"
                                    item-value="value"
                            >
                            </v-select>

                        </div>
                        <div class="col-6">
                            <v-file-input
                                    v-model="assignment_question.question_image"
                                    show-size
                                    counter
                                    outlined
                                    dense
                                    label="Question Image"
                            ></v-file-input>
                        </div>

                        <div class="col-3">
                            <v-switch
                                    v-model="assignment_question.is_active"
                                    label="Status"
                            >
                            </v-switch>
                        </div>

                        <div class="col-12 text-right mt-4">
                            <v-btn
                                    class="btn btn-standard"
                                    depressed
                                    @click="resetForm"
                            >Cancel
                            </v-btn>
                            <v-btn
                                    class="text-white ml-2 btn btn-primary"
                                    depressed
                                    @click="createAndUpdate"
                                    :loading="isBusy"
                            >Save
                            </v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>

        </v-dialog>
    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import AssignmentQuestionService from "@/core/services/assignement/AssignmentQuestionService";
    import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
    import CoursesService from "@/core/services/courses/CoursesService";
    import {AssignmentMixin} from "@/mixins/AssignmentMixin";

    const assignmentQuestionService = new AssignmentQuestionService();

    const gradeLevelService = new GradeLevelService();
    const courseService = new CoursesService();

    export default {
        name: "assignment-create-and-update",
        mixins: [AssignmentMixin],
        validations: {
            assignment_question: {
                title: {required},
                description: {required},
            }
        },
        data() {
            return {
                dialog: false,
                menu: false,
                menu2: false,
                isBusy: false,
                edit: false,
                assignment_question: {
                    title: null,
                    description: null,
                    course_id: null,
                    type: null,
                },
                answer_acceptances: [
                    {
                        title: 'Select One',
                        value: 'select_one'
                    },
                    {
                        title: 'Select Multiple',
                        value: 'select_multiple'
                    },
                    {
                        title: 'Select Specific',
                        value: 'select_specific'
                    },
                    {
                        title: 'Free Text',
                        value: 'free_text'
                    },
                    {
                        title: 'Image',
                        value: 'image'
                    }
                ], marking_types: [
                    {
                        title: 'Automate',
                        value: 'automate'
                    },
                    {
                        title: 'Migrate',
                        value: 'migrate'
                    }
                ]

            };
        }, mounted() {
            this.getPrograms()
        },
        computed: {},
        watch: {},
        methods: {
            formatDatePickerWise(date) {
                if (!date) return null
                const [day, month, year] = date.split('/')
                return `${year}-${month}-${day}`
            },
            getGrades() {
                gradeLevelService.getByProgram(this.assignment.program_id).then(response => {
                    this.gradesLevels = response.data;
                });
            },

            getCourses() {
                let query = {
                    program_id: this.assignment.program_id,
                    grade_id: this.assignment.grade_id,
                };
                courseService.paginate(query).then(response => {
                    this.courses = response.data.data;
                });
            },
            showModal(assignment = {}) {
                if (assignment.id) {
                    this.assignment = assignment;
                    this.assignment.start_date = this.formatDatePickerWise(this.assignment.start_date);
                    this.assignment.end_date = this.formatDatePickerWise(this.assignment.end_date);
                    this.edit = true
                    this.getPrograms();

                } else {
                    this.edit = false;
                    this.resetForm();
                }
                this.dialog = true;
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                // We pass the ID of the button that we want to return focus to
                // when the modal has hidden
                this.dialog = false;
            },
            createAndUpdate() {
                this.$v.$touch();
                if (this.$v.assignment_question.$error) {
                    setTimeout(() => {
                        this.$v.$reset();
                    }, 3000);
                } else {
                    if (this.edit) {
                        this.__update();
                    } else {
                        this.__create();
                    }
                }
            },
            __create() {
                this.isBusy = true;
                assignmentQuestionService.store(this.assignment_question).then(response => {
                    this.isBusy = false;
                    this.resetForm()
                    this.$snotify.success("Created successfully");
                });
            },
            __update() {
                this.isBusy = true;
                assignmentQuestionService.update(this.assignment_question.id, this.assignment_question).then(response => {
                    this.isBusy = false;
                    this.resetForm()
                    this.$snotify.success("Updated successfully");

                });
            }, resetForm() {
                this.assignment = {
                    level_id: null,
                    program_id: null,
                    title: null,
                    start_date: null,
                    end_date: null,
                    is_active: true
                }
                this.edit = false
                this.$v.$reset()
                this.hideModal()
                this.$emit("refresh");
            }
        },
    };
</script>
<style>
    .v-application--wrap {
        min-height: 0 !important;
    }
</style>
