<template>
    <v-app>
        <div class="card ">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-sm-12">
                        <h3>
                            Assignment Questions
                        </h3>
                        <router-link :to="{name:'dashboard'}">
                            Dashboard
                        </router-link>
                        \
                        Assignment Questions
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 text-right mb-3">
                        <button @click="openDialog()" class="btn btn-primary ml-2">
                        Add Question
                        </button>
                    </div>
                    <div class="row mb-5 bg-filter">
                        <div class="col-md-2">
                            <v-select
                                    class="form-control"
                                    outlined
                                    :items="programs"
                                    v-model="search.program_id"
                                    @change="getGrades"
                                    dense
                                    item-text="title"
                                    item-value="id"
                                    label="Program"
                            >
                            </v-select>

                        </div>
                        <div class="col-md-2" v-show="search.program_id">
                            <v-select
                                    class="form-control"
                                    outlined
                                    :items="gradesLevels"
                                    v-model="search.grade_id"
                                    dense
                                    item-text="title"
                                    item-value="id"
                                    label="Semester/Year"
                                    @change="getCourses"
                            >
                            </v-select>
                        </div>
                        <div class="col-md-2" v-show="search.program_id">
                            <v-select
                                    class="form-control"
                                    outlined
                                    :items="courses"
                                    v-model="search.course_id"
                                    dense
                                    item-text="title"
                                    item-value="id"
                                    label="Course"
                            >
                            </v-select>
                        </div>

                        <div class="col-md-2 my-auto">
                            <v-btn class="btn btn-primary text-white" depressed :loading="isBusy" @click="__get">
                                Search
                            </v-btn>
                            <!--                                    <button class="btn btn-secondary ml-2" @click="reset">Reset</button>-->
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-stripe">
                                <thead>
                                <th>Title</th>
                                <th>Course</th>
                                <th>Program</th>
                                <th>Semester/Year</th>
                                <th>Added Date</th>
                                <th>Due Date</th>
                                <th>Added By</th>
                                <th>Type</th>
                                <th>Action</th>
                                </thead>
                                <tbody>
                                <tr v-for="(assignment, index) of assignment_questions" :key="index">
                                    <td>
                                               <router-link :to="{ name:'assignment-summary',params:{
                                                          assignment_id: assignment.id
                                                        }}">
                                            {{assignment.title}}
                                        </router-link>
                                        <i class="fas fa-circle"
                                           :class="assignment.is_active?'dot-active':'dot-inactive'"></i>
                                    </td>
                                    <td v-if="assignment.course">{{ assignment.course.title }}</td>
                                    <td v-if="assignment.program">{{ assignment.program.title }}</td>
                                    <td v-if="assignment.course">{{ assignment.course.grade.title }}</td>
                                    <td>{{ assignment.start_year }}</td>
                                    <td>{{ assignment.end_year ? assignment.end_year : 'Running' }}</td>

                                    <td>
                                        <div class="kt-widget__toolbar">

                                            <b-dropdown
                                                    size="sm"
                                                    variant="link"
                                                    toggle-class="custom-v-dropdown"
                                                    no-caret
                                                    right
                                                    no-flip
                                                    text="Actions"
                                            >
                                                <template v-slot:button-content>
                                                    <a href="#" class="" data-toggle="dropdown">
                                                        <!--              <span class="svg-icon svg-icon-success svg-icon-2x">-->
                                                        <!--begin::Svg Icon-->
                                                        <i class="flaticon-more-1"></i>
                                                        <!--end::Svg Icon-->
                                                        <!--              </span>-->
                                                    </a>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover">
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link"
                                                           @click.prevent="openDialog(assignment)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text">Edit</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item"
                                                    >
                                                        <a href="#" class="navi-link"
                                                           @click.prevent="deleteAcademicYear(assignment.id)">
                                                                    <span class="navi-icon">
                                                                      <i class="flaticon-delete"></i>
                                                                    </span>
                                                            <span class="navi-text">Delete</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                                <!--end::Navigation-->
                                            </b-dropdown>
                                        </div>


                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row" v-if="assignment_questions.length>0">
                        <div class="col-12 text-center">
                            <b-pagination
                                    @input="__get"
                                    v-model="page"
                                    :total-rows="total"
                                    :per-page="perPage"
                                    first-number
                                    last-number
                            ></b-pagination>
                            <!--  -->
                        </div>
                    </div>
                </div>
            </div>
            <create-and-update ref="assignment" @refresh="__get"></create-and-update>
        </div>
    </v-app>
</template>
<script>
    import CreateAndUpdate from "./CreateAndUpdate";

    import  {AssignmentMixin} from "@/mixins/AssignmentMixin";

    export default {
        name: "assignmentQuestions",
        mixins:[AssignmentMixin],
        components: {
            CreateAndUpdate
        },
        data() {
            return {
                closeOnClick: true,

            };
        },
        mounted() {
            // this.getPrograms();
            this.__get();

        },
        methods: {
            __get() {
                this.getAssignmentsQuestions()
            },
            openDialog(assignment) {
                this.$refs["assignment"].showModal(assignment);
            },

            deleteAcademicYear(id) {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    callback: confirm => {
                        if (confirm) {
                            assignmentQuestionService.delete(id).then(response => {
                                this.$snotify.success("Classroom deleted");
                                this.__get();
                            });
                        }
                    }
                });
            },
        }
    };
</script>
